@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}
/* 
:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
} */

/* @media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
} */

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  color: black;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  background-color: white;
  /* color:white */
  /* color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb)); */
}

a {
  /* color: white; */
  text-decoration: none;
}
#gallery_main_body {
  background-image: url(../assets/bg_gallery.png);
  object-fit: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.swiper-button-next {
  background-color: rgba(253, 253, 253, 1);
  margin-left: 60px;
  border-radius: 5px;
  background-image: url(../assets/next_arrow.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  -webkit-box-shadow: -1px 2px 14px 5px rgba(209, 209, 209, 0.37);
  -moz-box-shadow: -1px 2px 14px 5px rgba(209, 209, 209, 0.37);
  box-shadow: -1px 2px 14px 5px rgba(209, 209, 209, 0.37);
}

.swiper-button-next::after {
  display: none;
}

.swiper-button-prev {
  background-color: rgba(253, 253, 253, 1);
  margin-right: 60px;
  border-radius: 5px;
  background-image: url(../assets/prev_arrow.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  -webkit-box-shadow: -1px 2px 14px 5px rgba(209, 209, 209, 0.37);
  -moz-box-shadow: -1px 2px 14px 5px rgba(209, 209, 209, 0.37);
  box-shadow: -1px 2px 14px 5px rgba(209, 209, 209, 0.37);
}

.swiper-button-prev::after {
  display: none;
}

@media only screen and (max-width: 768px) {
  .swiper-button-next {
    border-radius: 5px;
    background-color: rgba(253, 253, 253, 0.5);
    padding: 0;
    background-size: 100% auto;
  }

  .swiper-button-prev {
    border-radius: 5px;
    background-color: rgba(253, 253, 253, 0.5);
    padding: 0;
    background-size: 100% auto;
  }
}
